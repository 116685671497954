import { graphql } from "gatsby"
import React, { useContext } from "react"
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io"
import { useSwipeable } from "react-swipeable/es"
import { PageContext } from "../../context/PageContext"
import useDatoMicrocopy from "../../hooks/useDatoMicrocopy"
import { isBrowser } from "../../utils"
import ProductUserAreaInfo from "../molecules/ProductUserAreaInfo"
import ProductFilter, { ProductFilterProduct } from "../organisms/product/ProductFilter"
import ProductSearchCategories from "../organisms/productSearch/ProductSearchCategories"
import ProductSearchFilters from "../organisms/productSearch/ProductSearchFilters"
import ProductSearchResults from "../organisms/productSearch/ProductSearchResults"
import * as styles from "./ProductSearchPage.module.scss"

type ProductCategoryPageProps = {
  category: Queries.DatoProductCategoryFragment | null
  products: Queries.DatoProductCategoryPageQuery["products"]["nodes"]
}

const ProductCategoryPage = (props: ProductCategoryPageProps) => {
  //console.log("Rendering ProductCategoryPage", props)
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("common", locale)
  const [sidebarVisible, setSidebarVisible] = React.useState(true)

  function toggleSidebar() {
    setSidebarVisible(!sidebarVisible)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => setSidebarVisible(false),
    onSwipedRight: () => setSidebarVisible(true),
    preventScrollOnSwipe: true,
    trackMouse: false,
  })

  const { category } = props
  if (!category) {
    return null
  }

  return (
    <div className={`${styles.container} ${sidebarVisible ? styles.visible : styles.hidden}`} {...swipeHandlers}>
      <div className={`${styles.sidebar}`}>
        <div className={styles.sticky}>
          <div>
            {/* <ProductSearchField /> */}
            <ProductSearchCategories />
            <ProductSearchFilters units={"metric"} />
          </div>
        </div>
        <button
          className={styles.toggleButton}
          onClick={toggleSidebar}
          aria-label={sidebarVisible ? t("close", "Close") : t("open", "Open")}>
          {sidebarVisible ? <IoIosArrowBack /> : <IoIosArrowForward />}
        </button>
      </div>
      <div className={styles.content}>
        <h1>{category.title}</h1>
        <ProductUserAreaInfo locale={locale} />
        {category.description && <p className="lead">{category.description}</p>}
        <ProductSearchResults>
          {/* SSR renderoidaan kaikki tuoteperheen tuotteet. */}
          <ProductFilter
            query={category.pimCode ? `categories:${category.pimCode}` : undefined}
            pageSize={isBrowser ? 32 : 1000}
            products={props.products as ProductFilterProduct[]}
          />
        </ProductSearchResults>
      </div>
    </div>
  )
}

export default ProductCategoryPage

export const query = graphql`
  fragment DatoProductCategory on DatoCmsProductCategory {
    pimCode
    title
    description
    seoMetaTags {
      tags
    }
  }
`
