import { graphql, PageProps } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import ProductCategoryPage from "../components/pages/ProductCategoryPage"
import ProductSearchContextProvider from "../context/ProductSearchContext"
//import * as styles from "./DatoProductCategory.module.scss"

type DatoProductCategoryPageProps = PageProps<
  Queries.DatoProductCategoryPageQuery,
  Queries.DatoProductCategoryPageQueryVariables
>

const DatoProductCategory = (props: DatoProductCategoryPageProps) => {
  //console.log("Rendering DatoProductCategory", props)
  const { data } = props

  if (!data) {
    console.error("No data for gatsby-template DatoProductCategory")
    return null
  }

  return (
    <ProductSearchContextProvider>
      <DefaultLayout
        mainMenu={data.website?.mainMenu}
        footer={data.website?.footer}
        seoMetaTags={data.category?.seoMetaTags}>
        <ProductCategoryPage category={data.category} products={data.products.nodes} />
      </DefaultLayout>
    </ProductSearchContextProvider>
  )
}

export default DatoProductCategory

export const query = graphql`
  # $originalId parametri viittaa lokalisoimattomaan route recordiin
  query DatoProductCategoryPage(
    $originalId: String!
    $locale: String!
    $localePim: String!
    $pimCode: String!
    $fallbackLocales: [String!]
  ) {
    website: datoCmsWebsite(locale: $locale, fallbackLocales: $fallbackLocales) {
      mainMenu {
        ...DatoMainNavTree
      }
      footer {
        ...DatoFooter
      }
    }
    category: datoCmsProductCategory(
      originalId: { eq: $originalId }
      locale: $locale
      fallbackLocales: $fallbackLocales
    ) {
      ...DatoProductCategory
    }
    products: allHarviaProduct(filter: { locale: { eq: $localePim }, categories: { eq: $pimCode } }) {
      nodes {
        identifier
        name
      }
    }
  }
`
